import axios from 'axios'
export default {
  async generate(data) {
    return new Promise((resolve, reject) => {
      axios
        .post('pin_card/generate', data)
        .then(res => {
          resolve(res)
        })
        .catch((err) => {
          reject(err)
        })
    })
  },
  async getExportUrl() {
    return new Promise((resolve, reject) => {
      axios
        .get('pin_card/export/excel/signedurl')
        .then(res => {
          resolve(res)
        })
        .catch((err) => {
          reject(err)
        })
    })
  }
}