<template>
  <WsMain>
    <WsCrud
      :modelName="$store.state.stone_model[modelName].modelName"
      :label="$store.state.stone_model[modelName].label"
      :fields="$store.state.stone_model[modelName].fields"
      :liveSearching="true"
      :showFields="showFields"
      :expandable="false"
      :showExpand="false"
      :labelInLocale="true"
      :infiniteScroll="true"
      :titleBarCustomBtns="titleBarCustomBtns"
      @title-bar-custom-btn-click="$_titleBarCustomBtnClick($event)"
    ></WsCrud>
  </WsMain>
</template>

<script>
import S_PinCard from "@/__vue2stone_cms/service/api/v1/pin_card";
export default {
  metaInfo() {
    return {
      title: this.$store.state.stone_model[this.modelName].label,
    };
  },

  data() {
    return {
      modelName: "pin_card",
      showFields: ["pin", "status", "user", "service_plan", "expired_at"],
      titleBarCustomBtns: [
        {
          label: this.$t("匯出"),
          event: "export-excel",
        },
        {
          label: this.$t("PIN 產生"),
          to: "/pin_card/generate",
        },
      ],
    };
  },

  methods: {
    $_titleBarCustomBtnClick($event) {
      if ($event == "export-excel") {
        this.$_onExportExcel();
      }
    },
    async $_onExportExcel() {
      const res = await S_PinCard.getExportUrl();
      window.open(res.data, "_blank");
    },
  },
};
</script>